<template lang="vi">
<div>
    <page-header :title="`Tạo mới thông tin`" :items="breadcrumbs" />
    <div v-if="!loading">
        <b-row>
            <b-col lg="12">
                <b-card>
                    <b-form-group>
                        <label v-show="category_filter == null">Tiêu đề <span class="red">*</span></label>
                        <b-form-textarea v-model="form.validate.ten" id="textarea-default" class="form-control-sm" rows="1" no-resize placeholder="Nhập tiêu đề"></b-form-textarea>
                    </b-form-group>
                    <b-form-group>
                        <label>Lĩnh vực <span class="red">*</span></label>
                        <b-form-select v-model="form.validate.linhVuc" aria-placeholder="a">
                            <option v-for="(cat) in categories" :key="cat" :value="cat">
                                {{ cat }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                   <b-form-group>
                        <label>Nội dung <span class="red">*</span></label>
                        <quill-editor :options="editorOption" @change="onEditorChange($event)" />
                    </b-form-group>
                    <b-form-group>
                        <div v-if="showAddErrorMessage" class="alert alert-danger mt-3" role="alert">
                            {{addErrorMessage}}
                        </div>
                        <b-button variant="success" class="mr-2 mt-3" @click.prevent="create">
                            Lưu
                        </b-button>
                        <b-button variant="danger" class="mt-3" @click.prevent="toListView">
                            Huỷ
                        </b-button>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
    </div>
</div>
</template>

<script>
import PageHeader from '../../../components/page-header';
import Multiselect from 'vue-multiselect';
import {
    httpClient
} from "../../../_utils/httpClient";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {
    quillEditor
} from 'vue-quill-editor'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/vi';
import Swal from 'sweetalert2';

export default {
    name: 'create',
    components: {
        PageHeader,
        Multiselect,
        quillEditor,
        DatePicker
    },
    data: function () {
        return {
            category_filter: null,
            categories: [],
            content: '',
            editorOption: {
                placeholder: 'Nhập nội dung ở đây....',
            },
            breadcrumbs: [{
                text: 'Trang chủ',
                to: '/admin/home',
            }, {
                text: 'Thông tin',
                to: '/admin/informations',
            }, {
                text: 'Thêm mới',
                to: '#',
                active: true,
            },],
            showAddErrorMessage: false,
            addErrorMessage: '',
            form: {
                validate: {
                    ten: '',
                    linhVuc: '',
                    noiDung: '',
                    guiThongBao: false,
                },
            },
            options: [{ text: 'Có', value: true }, { text: 'Không', value: false }],
            loading: false,
        };
    },
    watch: {
        'form.preview': {
            handler: function () {
                console.log(this.form.preview);
            },
            deep: true,
        },
        category_filter() {
            this.form.validate.phanLoai = this.category_filter;
        }
    },
    methods: {
        onEditorChange(event) {
            this.form.validate.noiDung = event.html;
        },
        async toListView() {
            await this.$router.push({
                name: 'admin.informations.list'
            })
        },
        validator() {
            let mappingErrorName = {
                ten: 'Tiêu đề',
                linhVuc: 'Lĩnh vực',
                noiDung: 'Nội dung',
                guiThongBao: 'Gửi thông báo',
            }
            for (let key in this.form.validate) {
                if(key == 'guiThongBao') {
                    continue;
                }
                if (this.form.validate[key] == null || this.form.validate[key].length < 1 || this.form.validate[key].replace('<p>','').replace('</p>','').trim() == "") {
                    this.showAddErrorMessage = true
                    this.addErrorMessage = mappingErrorName[key] + ` không được trống.`
                    return false
                }
            }
            return true
        },
        async create() {
            if (!this.validator()) return;
            await Swal.queue([{
                title: 'Tạo mới thông tin này',
                confirmButtonText: 'Lưu',
                cancelButtonText: 'Hủy bỏ',
                cancelButtonColor: '#f46a6a',
                confirmButtonColor: '#34c38f',
                showCancelButton: true,
                showconfirmButton: true,
                preConfirm: async () => {
                    this.showAddErrorMessage = false
                    let payload = {
                        ...this.form.validate
                    }
                    let response = await httpClient.post(`decision`, payload)
                    if (response.data.code === 0) {
                        Swal.insertQueueStep({
                            title: 'Tạo mới thành công'
                        }),
                            await this.$router.push({
                                name: 'admin.informations.list'
                            });
                    } else {
                        this.showAddErrorMessage = true
                        this.addErrorMessage = response.data
                    }
                    return true;
                }

            },]);
        },
        async getCategories() {
            let response = await httpClient.get(`config`, {
                params: {
                    name: 'linhvucquyetdinh'
                }
            })
            this.categories = response.data.data.map(el => {
                return el.name
            })
        },
    },
    async created() {
        await this.getCategories();
    },
};
</script>

<style lang="scss" scoped>

.action-column {
    width: 120px;
    text-align: center;
}

.ql-editor.ql-blank::before {
    content: "Nhập nội dung " !important;
}
</style><style>
.red {
    color: red;
}

.style-poiter {
    cursor: pointer;
}

.style-height {
    height: 41px;
}

.ql-container {
    min-height: 300px;
    max-height: 500px;
    overflow: auto;
}
</style>
